import React from 'react';

const FourOhFour = () => {
  return(
    <>
      404
    </>
  )
};

export default FourOhFour;
